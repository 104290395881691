import React from "react";

interface IProps {
  isClicked: boolean;
  onClick: () => void;
}

const ExpandedViewIcon: React.FC<IProps> = ({ isClicked, onClick }) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Expanded View"
    onClick={onClick}
    style={{ cursor: "pointer", borderRadius: 2 }}
  >
    <rect
      x="0.5"
      y="0.5"
      width="35"
      height="35"
      rx="1.5"
      fill="#F2F2F2"
      stroke={isClicked ? "#979797" : "#C2C2C2"}
      strokeWidth={isClicked ? "3" : "1"}
    />
    <rect x="7" y="7" width="16" height="1" fill="#979797" />
    <rect x="25" y="7" width="6" height="6" fill="#979797" />
    <rect x="25" y="16" width="6" height="6" fill="#979797" />
    <rect x="25" y="25" width="6" height="6" fill="#979797" />
    <rect x="7" y="11" width="10" height="1" fill="#C4C4C4" />
    <rect x="7" y="9" width="13" height="1" fill="#C4C4C4" />
    <rect x="7" y="16" width="16" height="1" fill="#979797" />
    <rect x="7" y="25" width="16" height="1" fill="#979797" />
    <rect x="7" y="20" width="10" height="1" fill="#C4C4C4" />
    <rect x="7" y="29" width="10" height="1" fill="#C4C4C4" />
    <rect x="7" y="18" width="13" height="1" fill="#C4C4C4" />
    <rect x="7" y="27" width="13" height="1" fill="#C4C4C4" />
  </svg>
);

export default ExpandedViewIcon;
