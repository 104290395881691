import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

interface IProps {
  color: string;
}

const TwitterIcon: React.FC<IProps> = ({ color }) => {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path
        fill={color}
        fillRule="evenodd"
        d="M21 6.776c-.661.3-1.373.505-2.12.596.762-.468 1.348-1.21 1.623-2.095-.714.434-1.503.749-2.346.918C17.485 5.459 16.525 5 15.462 5c-2.038 0-3.692 1.696-3.692 3.787 0 .296.032.585.095.862-3.069-.158-5.79-1.664-7.612-3.958-.318.561-.5 1.213-.5 1.906 0 1.313.652 2.472 1.643 3.152-.604-.019-1.175-.191-1.673-.473v.047c0 1.835 1.273 3.366 2.963 3.713-.31.089-.636.133-.973.133-.238 0-.47-.023-.695-.067.47 1.504 1.833 2.599 3.45 2.628-1.264 1.016-2.857 1.622-4.587 1.622-.298 0-.593-.017-.881-.052C4.634 19.373 6.576 20 8.66 20c6.794 0 10.508-5.77 10.508-10.774 0-.164-.003-.329-.01-.49.722-.534 1.349-1.2 1.842-1.96"
      />
    </SvgIcon>
  );
};

export default TwitterIcon;
