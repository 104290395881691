import { useRouter } from "next/router";
import { useAuth } from "@gannettdigital/community-hub-components";
import { useEffect } from "react";
import { useAnalytics } from "@/services/analyticsService";

const AnalyticsPageView = () => {
  const router = useRouter();
  const { hasMadeInitialGupCall } = useAuth();
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    if (hasMadeInitialGupCall) {
      trackEvent({
        pageType: "obituaries",
        eventType: "pageview",
      });
    }
    const handleRouteChange = (url: string) => {
      if (hasMadeInitialGupCall) {
        trackEvent({
          pageType: "obituaries",
          eventType: "pageview",
        });
      }
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events, hasMadeInitialGupCall]);

  return null;
};

export default AnalyticsPageView;
