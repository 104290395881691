import { useRouter } from "next/router";
import React, { useState } from "react";
import { IFilters, IFiltersContext, IFiltersProviderProps, RESULT_VIEW } from "./FiltersProvider.types";

export const getQueryValue = (query: Record<string, string | string[] | undefined>, key: string) => {
  let value = query[key];
  if (typeof value === "undefined") return undefined;

  value = Array.isArray(value)
    ? value[value.length - 1] // last element overwrites all others
    : value;

  return value;
}
const DEFAULT_CONTEXT: IFiltersContext = {
  currentView: RESULT_VIEW.expanded,
  changeView: (view: RESULT_VIEW) => { },
  applyFiltersToRoute: (filters: IFilters) => { },
  goToPage: (page: string | number) => { },
}

export const FiltersContext = React.createContext(DEFAULT_CONTEXT);

// Handles all filters & view settings related to obits.
// Reads query params and stores data in session storage.
const FiltersProvider: React.FC<IFiltersProviderProps> = ({ children }) => {
  const router = useRouter();
  const { query } = router;

  let defaultView = getQueryValue(query, "display");
  if (!defaultView || !(defaultView in RESULT_VIEW)) {
    defaultView = RESULT_VIEW.expanded;
  }

  const [currentView, setCurrentView] = useState(defaultView as RESULT_VIEW);

  const applyFiltersToRoute = (filters: IFilters, keepOldParams = false) => {
    const url = keepOldParams
      ? new URL(window.location.href)
      : new URL("/", window.location.href); // reset route so query params are lost

    const searchParams = url.searchParams;

    Object.entries(filters).forEach(kv => {
      const [key, value] = kv;
      if (!value) return;
      searchParams.set(key, value.toString());
    });
    searchParams.sort();

    router.push(url);
  };

  const changeView = (view: RESULT_VIEW) => {
    // TODO: setStorage
    setCurrentView(view);
    applyFiltersToRoute({ display: view }, true)
    // push to router instead of local view var?
  }

  const scrollToTopOfPage = () => {
    window.scrollTo(0, 0);
  };

  const goToPage = (page: string | number) => {
    // we want to keep query string, but change the page number

    // normal search
    if (query.search) {
      const search = getQueryValue(query, "search")

      applyFiltersToRoute({ page, search });
    }

    // publication dropdown
    else if (query.ids && query.pubname) {
      const ids = getQueryValue(query, "ids")
      const pubname = getQueryValue(query, "pubname")

      applyFiltersToRoute({
        page,
        ids,
        pubname,
      });
    }

    // advanced search
    else if (
      query.dateRange ||
      query.city ||
      query.state ||
      query.funeralHome
    ) {
      const after = getQueryValue(query, "after");
      const before = getQueryValue(query, "before");
      const city = getQueryValue(query, "city");
      const dateRange = getQueryValue(query, "dateRange");
      const funeralHome = getQueryValue(query, "funeralHome");
      const state = getQueryValue(query, "state");


      applyFiltersToRoute({
        page,
        after,
        before,
        city,
        dateRange,
        funeralHome: funeralHome || "",
        state
      });
    }

    // show me selected
    else if (query.showMe) {
      const showMe = getQueryValue(query, "showMe");
      const character = getQueryValue(query, "character");

      applyFiltersToRoute({
        page,
        showMe,
        character,
      });
    }

    // no query params recognised, go to page
    else {
      applyFiltersToRoute({ page });
    }

    scrollToTopOfPage();
  };

  return <FiltersContext.Provider value={{ currentView, changeView, applyFiltersToRoute, goToPage }}>{children}</FiltersContext.Provider>
}

export default FiltersProvider;