import { parseStorage } from "@/helpers/storage";
import { SESSION_STORAGE_KEY } from "@/hooks/useAuth";
import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { concatPagination } from "@apollo/client/utilities";
import merge from "deepmerge";
import isEqual from "lodash/isEqual";
import { AppProps } from "next/app";
import getConfig from "next/config";
import { useMemo } from "react";

export const GRAPHQL_SERVICES = {
  OBITS_SERVICE: "OBITS_SERVICE",
  OBITS_MEMORIES: "OBITS_MEMORIES",
  OBITS_LISTINGS: "OBITS_LISTINGS"
};

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

const { publicRuntimeConfig } = getConfig();

let apolloClient: ApolloClient<NormalizedCacheObject>;

const createApolloClient = () => {
  const { graphqlEndpoint, graphqlApiKey, memoryApiKey, memoryEndpoint, listingEndpoint, listingAdminKey } =
    publicRuntimeConfig;

  const isServer = typeof window === "undefined";

  const authMiddleware = new ApolloLink((operation, forward) => {
    const authState = !isServer && parseStorage(SESSION_STORAGE_KEY);
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        "x-user-role": authState?.roles?.[0] || "anonymous",
        ...(authState?.isAuthenticatedInCurrentContext
          ? { Authorization: `Bearer ${authState.obitsJWT}` }
          : {}),
      },
    }));

    return forward(operation);
  });

  const obitsServiceHttpLink = new HttpLink({
    uri: graphqlEndpoint,
    credentials: "same-origin",
    headers: {
      "x-api-key": graphqlApiKey,
    },
  });

  const obitsMemoriesHttpLink = new HttpLink({
    uri: memoryEndpoint,
    credentials: "same-origin",
    headers: {
      "x-api-key": memoryApiKey,
    },
  });

  const obitsListingsHttpLink = new HttpLink({
    uri: listingEndpoint,
    credentials: 'same-origin',
    headers: {
      'x-hasura-admin-secret': listingAdminKey,
    },
  });

  const splitLink = ApolloLink.split(
    (operation) =>
      operation.getContext().serviceName === GRAPHQL_SERVICES.OBITS_MEMORIES,
    obitsMemoriesHttpLink, // apollo will send to obits-memories-service if serviceName is "OBITS_MEMORIES"

    ApolloLink.split(
      (operation) =>
        operation.getContext().serviceName === GRAPHQL_SERVICES.OBITS_LISTINGS,
      obitsListingsHttpLink, // apollo will send to obits-listings-service if serviceName is "OBITS_LISTINGS"
      obitsServiceHttpLink // otherwise will send to obits-service
    )
  );

  return new ApolloClient({
    ssrMode: isServer,
    link: from([authMiddleware, splitLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            allPosts: concatPagination(),
          },
        },
      },
    }),
  });
};

export const initializeApollo = (initialState = null) => {
  const _apolloClient = apolloClient ?? createApolloClient();
  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};

// takes the pageProps returned from getStaticProps() / getServerSideProps() for the current page
// and adds to them Apollo's cache data. From there, Next.js takes care of passing Apollo's cache data,
// along with any other page-specific props into the page component
export const addApolloState = (
  client: ApolloClient<NormalizedCacheObject>,

  pageProps: { props: any }
) => {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
};

export const useApollo = (pageProps: AppProps["pageProps"]) => {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);

  return store;
};
