import { IMarketConfig } from "./actions";
import { SET_IS_NATIVE_APP, SET_MARKET_CONFIG } from "./actionTypes";

const reducer = (
  state = {},
  action: { type: string; payload: IMarketConfig | boolean }
) => {
  switch (action.type) {
    case SET_MARKET_CONFIG: {
      return {
        ...state,
        config: action.payload
      };
    }
    case SET_IS_NATIVE_APP: {
      return {
        ...state,
        isNativeApp: action.payload
      };
    }
    default:
      return state;
  }
};

export default reducer;
