import styled from "@/components/contexts/ThemeProvider/styled";
import NextImage from "next/image";
import swooshBottomTop from "./swoosh-desktop-orange-bottom-top.svg";
import swooshTopBottom from "./swoosh-desktop-orange-top-bottom.svg";

interface IProps {
  flip?: boolean;
  className?: string;
}

const SwooshImage = ({ flip, className }: IProps) => {
  const src = flip ? swooshTopBottom : swooshBottomTop;

  return (
    <Root className={className} aria-hidden>
      <NextImage
        alt=""
        src={src}
        sizes="100vw"
        style={{
          width: "100%",
          height: "auto",
        }}
      />
    </Root>
  );
};

export default SwooshImage;

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  marginTop: -80,

  [theme.breakpoints.up("sm")]: {
    marginTop: -60,
    padding: "0 25px",
  },

  [theme.breakpoints.up("md")]: {
    marginTop: -150,
    padding: "0 50px",
  },

  [theme.breakpoints.up("lg")]: {
    padding: "0 100px",
  },
}));
