/* eslint-disable no-console */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["getHostValue"] }] */

const get = require("lodash/get");
const wc = require("../graphql/queries/getWebConfig");

class MarketService {
  constructor() {
    this.MARKETS = [];
  }

  initialize() {
    if (this.MARKETS.length > 0) return Promise.resolve();
    return fetch(process.env.GRAPHQL_ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": process.env.GRAPHQL_API_KEY,
      },
      body: JSON.stringify({
        query: wc.WEB_CONFIG_QUERY,
      }),
    })
      .then((response) => response.text())
      .then((response) => {
        this.setMarketConfig(JSON.parse(response));
      })
      .catch((error) => {
        console.error("received error ", error);
        throw error;
      });
  }

  setMarketConfig(conf) {
    const markets = get(conf, "data.web_config");
    if (markets && markets.length > 0) {
      this.MARKETS = conf.data.web_config;
    } else {
      console.error("Invalid market configuration");
    }
  }

  getMarketConfig(host) {
    const domain = host.replace("www.", "");
    return this.MARKETS.find((x) => x.domain === domain);
  }

  getHostValue(req) {
    return (
      get(req, "cookies.host") ||
      get(req, "headers['x-host']") ||
      get(req, "headers.host")
    );
  }
}

module.exports = new MarketService();
