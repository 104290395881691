import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import React from "react";

const ShareIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M14.913 15.845l-5.058-2.92c.094-.292.145-.602.145-.925 0-.32-.05-.628-.143-.917l5.061-2.923c.54.52 1.274.84 2.082.84 1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3c0 .125.008.248.022.368L8.63 9.481C8.161 9.177 7.601 9 7 9c-1.657 0-3 1.343-3 3s1.343 3 3 3c.598 0 1.156-.175 1.624-.477l5.397 3.117c-.014.118-.021.238-.021.36 0 1.657 1.343 3 3 3s3-1.343 3-3-1.343-3-3-3c-.811 0-1.547.322-2.087.845z"
      />
    </SvgIcon>
  );
};

export default ShareIcon;
