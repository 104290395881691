import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

interface IProps {
  color: string;
}

const FacebookIcon: React.FC<IProps> = ({ color }) => {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path
        fill={color}
        fillRule="evenodd"
        d="M12 3c4.97 0 9 4.054 9 9.055 0 4.52-3.291 8.266-7.594 8.945v-6.319h2.097l.399-2.617h-2.496v-1.699c0-.716.348-1.414 1.466-1.414h1.135V6.723s-1.03-.177-2.014-.177c-2.056 0-3.4 1.253-3.4 3.523v1.995H8.308v2.617h2.285V21C6.291 20.32 3 16.574 3 12.055 3 7.054 7.03 3 12 3z"
      />
    </SvgIcon>
  );
};

export default FacebookIcon;
