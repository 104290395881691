/**
 * CONFLUENCE: https://confluence.gannett.com/pages/viewpage.action?spaceKey=DS&title=gciAnalytics+Third+Party+Integration+Guide
 * DEMO: https://www.gannett-cdn.com/dcjs/prod/demo.html?siteCode=NREC&implementation=COBRAND
 * "client-action" {eventCategory}|{eventAction} - ex: obituary|share|twitter, obituary|sort|past-month
 * ["event-label"] {eventLabel} - ex: url, string, type
 * ["event-module-name"] {eventModuleName} - Gannett product name or acronym
 */
import useAuth from "@/hooks/useAuth";

interface ITrackEvent {
  action?: string;
  label?: string;
  module?: string;
  category?: string;
  pageType?: string;
  section?: string;
  eventType?: string;
  userAuthResponse?: string;
}

interface IGupResponse {
  meta: IMeta;
}

interface IMeta {
  status: string;
}

export const INDEX_PAGE_TYPE = "sub-section front";
export const DETAIL_PAGE_TYPE = "obit detail page";
export const HOWTO_PAGE_TYPE = "how to submit obituary page";

export const useAnalytics = () => {
  const { authState } = useAuth();

  const trackEvent = ({
    action,
    label,
    module,
    pageType,
    section = "obituaries",
    eventType = "custom",
    category,
  }: ITrackEvent) => {
    if (window.gciAnalytics) {
      try {
        const gupResponse = authState?.gupResponse as IGupResponse;
        const status: unknown = gupResponse?.meta?.status;

        window.gciAnalytics.user = gupResponse;
        window.gciAnalytics.view({
          "page-domain": window.location.host,
          "content-type": pageType,
          "content-ssts-section": section,

          "event-type": eventType,
          "event-action": action?.replaceAll(" ", "-").toLowerCase(),
          "event-label": label,
          "event-category": category,
          "event-module-name": module,
          "user-auth-response":
            status == 200 ? "success" : "failure response code from GUP",
        });
      } catch (error) {
        // the tracking library is bugged, this is a fallback
        // waiting on https://github.com/GannettDigital/gciAnalytics/pull/465/files
        console.error(error);
      }
    }
  };

  return {
    trackEvent,
  };
};
