import { IGetWebConfigQuery } from "@/graphql/queries/__generated__/getWebConfig.types";
import { SET_IS_NATIVE_APP, SET_MARKET_CONFIG } from "./actionTypes";

export type IMarketConfig = IGetWebConfigQuery["web_config"][0];

export const setMarketConfig = (config: IMarketConfig) => ({
  type: SET_MARKET_CONFIG,
  payload: config
});

export const setIsNativeApp = (flag: boolean) => ({
  type: SET_IS_NATIVE_APP,
  payload: flag
});
