import styled from "@/components/contexts/ThemeProvider/styled";
import { FeatureType } from "@/components/organisms/SubmitPageFeaturesSection/SubmitPageFeaturesSection";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const Div = styled(
  (
    props: React.HTMLAttributes<HTMLDivElement> & {
      src: string;
      withGradient: boolean;
    }
  ) => <div {...props} />,
  {
    shouldForwardProp: prop => prop !== "src" && prop !== "withGradient"
  }
)(({ theme, src, withGradient }) => ({
  height: 265,
  width: 265,
  backgroundImage: `${
    withGradient
      ? "linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #FFFFFF 85%), "
      : ""
  }url(${src})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",

  [theme.breakpoints.up("sm")]: {
    height: 340,
    width: 400
  },

  [theme.breakpoints.up("md")]: {
    height: 464,
    width: 464
  }
}));

interface IProps {
  featureType: FeatureType;
}

const SubmitPageFeatureImage: React.FC<IProps> = ({ featureType }) => {
  return (
    <Div
      src={`${publicRuntimeConfig.assetsPrefix}/static/images/${featureType}.svg`}
      withGradient={featureType !== FeatureType.donations}
    />
  );
};

export default SubmitPageFeatureImage;
