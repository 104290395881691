import { SelectChangeEvent } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import React, { useMemo } from "react";
import { FormControl, MenuItem, Select } from "./Dropdown.styled";

interface IAdditionalStyles {
  formControlStyles?: string;
  selectStyles?: string;
}

interface ISelectOptions {
  value: string | number;
  label: string;
}

interface IProps {
  value?: string;
  placeholder?: string;
  options: ISelectOptions[];
  additionalStyles: IAdditionalStyles;
  onChangeCallback: (e: SelectChangeEvent<unknown>, name: any) => void;
}

const Dropdown: React.FC<IProps> = ({
  value = "",
  placeholder,
  options,
  additionalStyles,
  onChangeCallback
}) => {
  const SelectOptions = useMemo(
    () =>
      options.map(o => (
        <MenuItem key={o.value} value={o.value}>
          {o.label}
        </MenuItem>
      )),
    [options]
  );

  return (
    <FormControl fullWidth className={additionalStyles?.formControlStyles}>
      <Select
        sx={{
          "& .MuiSelect-select .notranslate::after": placeholder
            ? {
              content: `"${placeholder}"`
            }
            : {}
        }}
        className={additionalStyles?.selectStyles}
        MenuProps={{
          classes: { paper: "selectPaper", list: "selectList" }
        }}
        classes={{ select: "select" }}
        displayEmpty
        value={value || ""}
        onChange={(e, child?: any) => {
          const name = child?.props?.children && child.props.children;
          if (onChangeCallback) onChangeCallback(e, name);
        }}
        input={<OutlinedInput />}
      >
        {SelectOptions}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
