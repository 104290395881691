import { FlexContainer } from "@/components/atoms";
import styled from "@/components/contexts/ThemeProvider/styled";

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between"
});

export const ItemContainer = styled("div")(({ theme }) => ({
  position: "relative",

  "& .desktop": {
    display: "none"
  },

  [theme.breakpoints.up("sm")]: {
    "& .mobile": {
      display: "none"
    },
    "& .desktop": {
      display: "block"
    }
  }
}));

export const FeatureFlexContainer = styled(
  (
    props: React.HTMLAttributes<HTMLDivElement> & {
      flexDirection: "row" | "row-reverse";
    }
  ) => <FlexContainer {...props} />,
  {
    shouldForwardProp: prop => prop !== "flexDirection"
  }
)(({ theme, flexDirection }) => ({
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(0, 3),

  [theme.breakpoints.up("sm")]: {
    margin: "auto",
    flexDirection,
    gap: 80,
    padding: theme.spacing(0, 8)
  },

  [theme.breakpoints.up("md")]: {
    gap: 100
  }
}));

export const TextContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    width: 350
  },

  [theme.breakpoints.up("md")]: {
    width: 430
  }
}));
