import getConfig from "next/config";
import Link from "next/link";
import React from "react";

const { publicRuntimeConfig } = getConfig();

const BASE_PATH = publicRuntimeConfig.BASE_PATH;

interface IProps {
  href: string;
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
}

const RouterLink: React.FC<IProps> = ({
  href,
  className,
  children,
  onClick,
}) => {
  return (
    <Link href={href} className={className} onClick={onClick}>
      {children}
    </Link>
  );
};

export default RouterLink;
