import styled from "@/components/contexts/ThemeProvider/styled";
import { Grid } from "@mui/material";
import getConfig from "next/config";
import Image from "next/image";
import React from "react";
import swooshDesktop from "./swoosh-desktop.svg";
import swooshMobile from "./swoosh-mobile.svg";

const attr = {
  sizes: "100vw",
  style: {
    width: "100%",
    height: "auto",
  },
};

export const Swoosh = () => {
  // NOTE: Do not use MUI `useMediaQuery` which instaniates late and can cause layout shift.
  return (
    <Grid container aria-hidden>
      <Container>
        <Image alt="" className="desktop" src={swooshDesktop} {...attr} />
        <Image alt="" className="mobile" src={swooshMobile} {...attr} />
      </Container>
    </Grid>
  );
};

export default React.memo(Swoosh);

const Container = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(8),
  width: "100%",
  position: "relative",

  "@media print": {
    display: "none",
  },

  "& .desktop": {
    display: "none",
  },

  [theme.breakpoints.up("md")]: {
    "& .mobile": {
      display: "none",
    },

    "& .desktop": {
      display: "initial",
    },
  },
}));

const { publicRuntimeConfig } = getConfig();
