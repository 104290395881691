import styled from "@/components/contexts/ThemeProvider/styled";
import { displayDate } from "@/helpers/dates";
import { unifySansRgFontFamily } from "@gannettdigital/community-hub-components";
import { Typography } from "@mui/material";
import React from "react";

const CustomTypography = styled(Typography)(() => ({
  "&.MuiTypography-body1": {
    fontFamily: unifySansRgFontFamily,
    fontSize: 12
  }
}));

interface IProps {
  publishDate: Date;
}

const PublishedDate: React.FC<IProps> = ({ publishDate }) => {
  return (
    <>
      {publishDate && (
        <CustomTypography variant="body1">
          {`Posted online on ${displayDate(publishDate)}`}
        </CustomTypography>
      )}
    </>
  );
};

export default React.memo(PublishedDate);
