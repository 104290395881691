import { setStorage } from "@/helpers/storage";
import { useAuth } from "@gannettdigital/community-hub-components";
import { useEffect, useState } from "react";

export const SESSION_STORAGE_KEY = "auth-state";

const useObitsWebAuth = () => {
  const auth = useAuth();
  const [authState, setAuthState] = useState(auth);

  useEffect(() => {
    setStorage(SESSION_STORAGE_KEY, auth?.authState);
    setAuthState(auth);
  }, [auth?.hasMadeInitialGupCall]);

  return authState;
};

export default useObitsWebAuth;
