// change both this and the .graphql file so you can get types
const WEB_CONFIG_QUERY = `query GetWebConfig {
  web_config {
    id
    domain
    title
    footer
    header
    mobile_ad_unit
    desktop_ad_unit
    external_links
    publication {
      id
      name
      site_code
      site_id
      publication_info {
        deadlines
        email
        phone
      }
    }
    web_config_filters(order_by: [{name: asc}]) {
      name
      id
      web_config_filter_tags {
        tag {
          value
          id
        }
      }
    }
  }
}`

module.exports = { WEB_CONFIG_QUERY };
