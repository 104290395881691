import styled from "@/components/contexts/ThemeProvider/styled";
import { IObituaryTagFragment } from "@/graphql/fragments/__generated__/obituaryTag.types";
import { obituaryTagsToPublishers } from "@/helpers/obituary";
import { unifySansRgFontFamily } from "@gannettdigital/community-hub-components";
import { Typography } from "@mui/material";
import React, { useMemo } from "react";

const CustomTypography = styled(Typography)(() => ({
  "&.MuiTypography-body1": {
    fontFamily: unifySansRgFontFamily,
    fontSize: 12,
    wordBreak: "break-word"
  }
}));

interface IProps {
  tags: IObituaryTagFragment[];
}

const Publishers: React.FC<IProps> = ({ tags }) => {
  const publishers = useMemo(() => {
    return obituaryTagsToPublishers(tags).join(", ");
  }, [tags]);

  return (
    <>
      {publishers && (
        <CustomTypography variant="body1">
          {`Published in ${publishers}`}
        </CustomTypography>
      )}
    </>
  );
};

export default React.memo(Publishers);
