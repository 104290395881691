import React from "react";

interface IProps {
  isClicked: boolean;
  onClick: () => void;
}

const ListViewIcon: React.FC<IProps> = ({ isClicked, onClick }) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="List View"
    onClick={onClick}
    style={{ cursor: "pointer", borderRadius: 2 }}
  >
    <rect
      x="0.5"
      y="0.5"
      width="35"
      height="35"
      rx="1.5"
      fill="#F2F2F2"
      stroke={isClicked ? "#979797" : "#C2C2C2"}
      strokeWidth={isClicked ? "3" : "1"}
    />
    <rect x="7" y="7" width="23" height="1" fill="#979797" />
    <rect x="7" y="9" width="18" height="1" fill="#C4C4C4" />
    <rect x="7" y="14" width="23" height="1" fill="#979797" />
    <rect x="7" y="21" width="23" height="1" fill="#979797" />
    <rect x="7" y="23" width="14" height="1" fill="#C4C4C4" />
    <rect x="7" y="28" width="23" height="1" fill="#979797" />
    <rect x="7" y="30" width="14" height="1" fill="#C4C4C4" />
    <rect x="7" y="16" width="18" height="1" fill="#C4C4C4" />
  </svg>
);

export default ListViewIcon;
