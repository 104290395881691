import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import React from "react";

const FlowerIcon: React.FC = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M14.6,11c-2.8,0.6-4.7,1.8-5.7,3.6v-3H9c4.5-0.3,5.2-9,4.1-9.9c-0.1-0.1-0.4-0.2-0.5-0.3
	c-0.9-0.4-1.6-0.3-2.2,0.1C9.7,1,9.1,0.6,8.5,0.6S7.4,0.9,6.9,1.5C6.4,1.2,5.9,1.1,5.6,1.1l0,0c-0.6,0-1,0.3-1.4,0.7l0,0
	c-1.4,1.9,0,9.3,3.6,9.9v3c-0.9-1.8-2.8-3.1-5.4-3.6l-1.2-0.3L1.6,12c1.2,3,2.7,5.1,4.5,6.5c0.6,0.4,1.4,0.8,2.2,0.8
	c1,0,1.8-0.4,2.3-0.8c1.9-1.4,3.4-3.6,4.7-6.5l0.5-1.1L14.6,11z M7,17.5c-1.3-1-2.5-2.6-3.6-4.7c2.4,0.8,3.6,2.4,3.8,5
	C7.2,17.6,7.1,17.6,7,17.5z M8.7,1.8C8.9,1.9,9,2,9.2,2.2C9.1,2.3,8,3,8,3L7.3,2.4C7.3,2.4,7.8,1.5,8.7,1.8z M5,5.8
	C4.7,4.6,4.7,3.3,5.2,2.6c0.1-0.1,0.2-0.2,0.4-0.2l0,0c0.3,0,0.7,0.3,1.6,1.1C6.1,4.5,5.5,5,5,5.8z M5.8,6.9C6.2,6.3,7.2,5.1,10,3.3
	c0.9-0.6,1.5-0.8,2-0.7c0.2,0.1,0.3,0.2,0.1,2.6c-0.3,4.1-1.9,4.9-2.8,5.1c-0.9,0.2-2-0.1-2.9-0.9C5.4,8.2,5.6,7.4,5.8,6.9z
	 M9.9,17.4c-0.1,0.1-0.3,0.2-0.5,0.3c0.2-2.5,1.5-4.1,4.1-5C12.5,14.9,11.3,16.4,9.9,17.4z"
      />
    </SvgIcon>
  );
};

export default FlowerIcon;
