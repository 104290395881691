import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

interface IProps {
  color: string;
}

const MailIcon: React.FC<IProps> = ({ color }) => {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path
        fill={color}
        fillRule="evenodd"
        d="M3 5.876C3 5.392 3.393 5 3.893 5h16.214c.493 0 .893.404.893.876v12.248c0 .484-.393.876-.893.876H3.893C3.4 19 3 18.596 3 18.124V5.876zm9 5.218L3 6.74v1.741l9 4.353 9-4.353v-1.74l-9 4.352z"
      />
    </SvgIcon>
  );
};

export default MailIcon;
