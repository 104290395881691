import { Colors } from "@/components/contexts/ThemeProvider/theme";
import { formatDate } from "@/helpers/dates";
import {
  serifFontFamily,
  unifySansBoldFontFamily,
  unifySansRgFontFamily
} from "@gannettdigital/community-hub-components";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker as MuiDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import isValid from "date-fns/isValid";
import React, { useCallback, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextField, Toolbar } from "./Datepicker.styled";

const VALID_FORMATTED_DATE = "01/01/1900";
const VALID_FORMATTED_DATE_LENGTH = VALID_FORMATTED_DATE.length;

const maxDateValidationMessage = "Future dates are not allowed.";

interface IProps {
  labelAsPlaceholder?: boolean;
  disableFuture?: boolean;
  label: string;
  onChangeCallback?: () => void;
  openTo?: "day" | "month" | "year";
  name: string;
  required?: boolean;
}

const CustomDatepicker: React.FC<IProps> = ({
  labelAsPlaceholder,
  disableFuture,
  label,
  onChangeCallback,
  openTo = "day",
  name,
  required
}) => {
  const { control, setError, clearErrors, setValue } = useFormContext();

  const validate = useCallback(
    (date: Date) => {
      if (!disableFuture) return;

      if (date > new Date()) {
        setError(name, {
          type: "manual",
          message: maxDateValidationMessage
        });
      } else {
        clearErrors(name);
      }
    },
    [disableFuture, name, setError, clearErrors]
  );

  const handleDateChange = (
    date: Date,
    value: string | null | undefined,
    props: {
      onChange: any;
      onBlur?: () => void;
      value?: any;
      name?: string;
      ref?: React.MutableRefObject<any>;
    }
  ) => {
    const formattedValue = value?.replace(/_/g, "") ?? "";
    if (date && isValid(date)) {
      validate(date);
      props.onChange(date);

      if (onChangeCallback) onChangeCallback();
    } else if (formattedValue.length === VALID_FORMATTED_DATE_LENGTH) {
      const currentTime = new Date();
      // Setting hours, minutes & seconds, because the new Date(formattedValue) would return hours to 00:00:00
      const selectedDate = new Date(
        new Date(formattedValue).setHours(
          currentTime.getHours(),
          currentTime.getMinutes(),
          currentTime.getSeconds()
        )
      );

      validate(selectedDate);
      props.onChange(selectedDate);
      if (onChangeCallback) onChangeCallback();
    } else {
      clearErrors();
      props.onChange(null);
      if (onChangeCallback) onChangeCallback();
    }
  };

  // @ts-ignore
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={labelAsPlaceholder ? null : undefined}
      render={({field}) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiDatePicker
            {...field}
            disableFuture
            label={label}
            showToolbar
            openTo={openTo}
            inputFormat="MMM dd, yyyy"
            ToolbarComponent={() => (
              <Toolbar>
                <div>{formatDate(field.value?.toDateString() as string, "y")}</div>
                <div>
                  {formatDate(field.value?.toDateString() as string, "E, MMM dd")}
                </div>
              </Toolbar>
            )}
            mask="___ __, ____"
            views={["year", "month", "day"]}
            onChange={(date, value) => {
              handleDateChange(date, value, {
                onChange: field.onChange,
                onBlur: field.onBlur,
                value: field.value,
                name: field.name
              });
              setValue(name, date);
            }}
            showDaysOutsideCurrentMonth
            orientation="portrait"
            renderInput={params => (
              <TextField {...params} required={required} />
            )}
            PopperProps={{
              placement: "bottom-end"
            }}
            PaperProps={{
              sx: {
                "& .MuiTypography-root": {
                  fontFamily: `${serifFontFamily}!important`
                },
                "& .MuiPickersDay-root": {
                  borderRadius: "50%",
                  fontSize: 18,
                  fontFamily: unifySansRgFontFamily
                },
                "& .PrivatePickersSlideTransition-root": {
                  minHeight: 230
                },
                "& .PrivatePickersFadeTransitionGroup-root": {
                  fontFamily: serifFontFamily
                },
                "& .PrivatePickersYear-root > *": {
                  fontFamily: serifFontFamily
                },
                "& .PrivatePickersYear-root > .Mui-selected": {
                  fontFamily: unifySansBoldFontFamily
                },
                "& .MuiPickersDay-dayOutsideMonth": {
                  color: "rgba(0, 0, 0, 0.1)!important"
                },
                "& .MuiMonthPicker-root": {
                  minHeight: 261
                },
                "& .MuiPickersDay-root:hover": {
                  backgroundColor: Colors.blue12
                },
                "& .MuiPickersDay-today": {
                  borderColor: `${Colors.blue5}!important`
                },
                "& .Mui-selected": {
                  backgroundColor: `${Colors.blue5}!important`,
                  color: `${Colors.blue5}!important`
                }
              }
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default CustomDatepicker;
