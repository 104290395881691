export enum RESULT_VIEW {
  expanded = "expanded",
  list = "list"
}

export interface IFilters {
  after?: string | Date | null;
  before?: string | Date | null;
  date?: string;
  display?: string;
  showMe?: string;
  character?: string;
  ids?: string;
  page?: string | number;
  search?: string;
  pubname?: string;
  city?: string;
  dateRange?: string;
  funeralHome?: string;
  state?: string;
}

export interface IFiltersContext {
  currentView: RESULT_VIEW;
  changeView: (view: RESULT_VIEW) => void;
  applyFiltersToRoute: (filters: IFilters) => void;
  goToPage: (page: string | number) => void;
}

export interface IFiltersProviderProps {
  children: React.ReactNode;
}