import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";

import app from "@/redux/reducer";

let store;

export default function initializeStore(initialState = {}) {
  store = createStore(
    combineReducers({
      app
    }),
    initialState,
    composeWithDevTools(applyMiddleware(thunkMiddleware))
  );

  return store;
}
