import styled from "@/components/contexts/ThemeProvider/styled";
import { serifFontFamily } from "@gannettdigital/community-hub-components";
import React from "react";

const Title = styled("div")(({ theme }) => ({
  fontFamily: serifFontFamily,
  fontSize: 18,
  fontStyle: "italic",
  margin: theme.spacing(2, 0),
  width: "100%"
}));

const ChildrenContainer = styled("span")(({ theme }) => ({
  paddingLeft: theme.spacing(1),

  "& > *": {
    display: "inline"
  },
  "& > style": {
    display: "none"
  },
}));

interface IProps {
  title: string;
  children?: React.ReactNode;
}

const SearchTitle: React.FC<IProps> = ({ title, children }) => {
  return (
    <Title>
      {title}
      {children && <ChildrenContainer>{children}</ChildrenContainer>}
    </Title>
  );
};

export default React.memo(SearchTitle);
