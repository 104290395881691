import styled from "@/components/contexts/ThemeProvider/styled";

const FlexContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: "auto",
  alignItems: "flex-start",

  [theme.breakpoints.up("sm")]: {
    gap: 25,
    flexDirection: "row"
  }
}));

export default FlexContainer;
