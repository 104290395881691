import { ButtonBase } from "@mui/material";
import React from "react";
import { Container, Typography } from "./LinkButton.styled";

interface IProps {
  name: string;
  onClick?: () => void;
}

const LinkButton: React.FC<IProps> = ({ name, onClick, ...rest }) => {
  return (
    <Container {...rest}>
      <ButtonBase onClick={onClick}>
        <Typography variant="body2">{name}</Typography>
      </ButtonBase>
    </Container>
  );
};

export default LinkButton;
