import styled from "@/components/contexts/ThemeProvider/styled";
import { Colors } from "@/components/contexts/ThemeProvider/theme";
import {
    serifFontFamily,
    unifySansRgFontFamily
} from "@gannettdigital/community-hub-components";
import MuiTextField from "@mui/material/TextField";

export const TextField = styled(MuiTextField)(() => ({
  width: "100%",
  "& .MuiOutlinedInput-input": {
    fontSize: 18
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderWidth: "1px"
  },
  "& .MuiInputLabel-root": {
    color: Colors.gray3,
    fontFamily: serifFontFamily,
    fontSize: 18
  }
}));

export const Toolbar = styled("div")(({ theme }) => ({
  fontFamily: unifySansRgFontFamily,
  backgroundColor: theme.palette.primary.main,
  padding: "24px",
  borderRadius: "4px 4px 0 0",
  fontWeight: 400,
  color: theme.palette.custom.white,
  "& :first-of-type": {
    fontSize: 16
  },
  "& :nth-of-type(2)": {
    fontSize: 32
  }
}));
