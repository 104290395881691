import styled from "@/components/contexts/ThemeProvider/styled";
import {
    Typography,
    TypographyProps
} from "@gannettdigital/community-hub-components";

const SubmitPageTitle = styled(
  (
    props: Omit<TypographyProps, "fontSize"> & {
      fontSize?: {
        xs?: string | number;
        sm?: string | number;
        md?: string | number;
      };
    }
  ) => <Typography {...props} />
)(({ theme, fontSize }) => ({
  fontSize: fontSize?.xs || 28,
  fontWeight: "bold",
  marginBottom: theme.spacing(0.5),

  [theme.breakpoints.up("sm")]: {
    textAlign: "left",
    marginBottom: theme.spacing(0),
    fontSize: fontSize?.sm || 30
  },

  [theme.breakpoints.up("md")]: {
    fontSize: fontSize?.md || 40
  }
}));

export default SubmitPageTitle;
