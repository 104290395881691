import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import React from "react";

function ArrowRightIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 15.253334,2.6423165 24,12 15.253334,21.357684 13.413333,19.389102 19.013334,13.400187 H 0 V 10.62754 H 19.013334 L 13.413333,4.6386215 Z" />
    </SvgIcon>
  );
}

export default ArrowRightIcon;
