class Logger {
  defaults() {
    return {
      host: "",
      ip: "",
      method: "",
      name: "obits-web",
      path: "",
      time: Date.now(),
      url: "",
      useragent: ""
    };
  }

  info = (msg: any, obj: Record<string, number | unknown> = {}, req?: any) => {
    const x = this.normalize(msg, obj, req);
    console.info(JSON.stringify(x));
  };

  log = (msg: string, obj: Record<string, number | unknown>, req?: any) => {
    const x = this.normalize(msg, obj, req);
    console.log(JSON.stringify(x));
  };

  warn = (msg: string, obj: Record<string, number | unknown>, req?: any) => {
    const x = this.normalize(msg, obj, req);
    console.warn(JSON.stringify(x));
  };

  error = (msg: string, error: Record<string, number | unknown>, req?: any) => {
    const x = this.normalize(msg, {}, req);
    console.error(JSON.stringify(x), "\n", error);
  };

  normalize = (
    msg: string,
    obj: Record<string, number | unknown>,
    req: any
  ) => {
    const x = { ...obj, msg, ...this.defaults() };

    if (req !== undefined) {
      x.method = req.method;
      x.url = req.url;
      x.path = req.path;
      x.host = req.hostname;
      x.ip = req.ip;
      x.useragent = typeof req.get === "function" ? req.get("user-agent") : "";
    }
    return x;
  };
}
const logger = new Logger();

export default logger;
