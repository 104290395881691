const NEWSLETTER_CONFIG = new Map([
  ["NAAS", "TEXAS-AUSTIN-NLETTER67"],
  ["PIND", "INDIANA-INDIANAPOLIS-NLETTER67"],
  ["NPRJ", "RHODEISLAND-PROVIDENCE-NLETTER67"],
  ["PCIN", "OHIO-CINCINNATI-NLETTER67"],
  ["NCHB", "MICHIGAN-CHEBOYGAN-NLETTER67"],
  ["NDAT", "MICHIGAN-ADRIAN-NLETTER67"],
  ["NDRP", "MICHIGAN-COLDWATER-NLETTER67"],
  ["NHDN", "MICHIGAN-HILLSDALE-NLETTER67"],
  ["NHOS", "MICHIGAN-HOLLAND-NLETTER67"],
  ["NISS", "MICHIGAN-IONIA-NLETTER67"],
  ["NMNN", "MICHIGAN-MONROE-NLETTER67"],
  ["NPNR", "MICHIGAN-PETOSKEY-NLETTER67"],
  ["NSLT", "MICHIGAN-SAULTSAINTMARIE-NLETTER6"],
  ["NSTJ", "MICHIGAN-STURGIS-NLETTER67"],
  ["PITH", "1007IJ-E-NLETTER67"],
  ["PBIN", "1007PS-E-NLETTER67"],
  ["PELM", "1007SG-E-NLETTER67"],
  ["PDTN", "1008DN-E-NLETTER67"],
  ["PDTF", "1008FP-E-NLETTER67"],
  ["PLID", "1008PA-E-NLETTER67"],
  ["PBUR", "1010FP-E-NLETTER67"],
  ["PREN", "1011GJ-E-NLETTER67"],
  ["PCHH", "1013CP-E-NLETTER67"],
  ["PBUC", "1019BT-E-NLETTER67"],
  ["PCHI", "1019CG-E-NLETTER67"],
  ["PCOS", "1019CT-E-NLETTER67"],
  ["PFRE", "1019FM-E-NLETTER67"],
  ["PLAN", "1019LE-E-NLETTER67"],
  ["PMAN", "1019MN-E-NLETTER67"],
  ["PMAR", "1019MS-E-NLETTER67"],
  ["PNEW", "1019NA-E-NLETTER67"],
  ["PPOC", "1019PH-E-NLETTER67"],
  ["PFTC", "1021CO-E-NLETTER67"],
  ["PFTM", "1026NP-E-NLETTER67"],
  ["PBRE", "1028FT-E-NLETTER67"],
  ["PIOC", "1033PC-E-NLETTER67"],
  ["PLAL", "1040DA-E-NLETTER67"],
  ["POPE", "1040DW-E-NLETTER67"],
  ["PMON", "1040NS-E-NLETTER67"],
  ["PSHR", "1040ST-E-NLETTER67"],
  ["PALE", "1040TT-E-NLETTER67"],
  ["PSPR", "1042NL-E-NLETTER67"],
  ["PBAC", "1051EN-E-NLETTER67"],
  ["PLSJ", "1051SJ-E-NLETTER67"],
  ["PPOH", "1051TH-E-NLETTER67"],
  ["PWIL", "1052M-E-NLETTER67"],
  ["NTGT", "ALABAMA-GADSDEN-NLETTER67"],
  ["NTTN", "ALABAMA-TUSCALOOSA-NLETTER67"],
  ["NTRE", "ARKANSAS-FORTSMITH-NLETTER67"],
  ["NMSH", "CALIFORNIA-MTSHASTA-NLETTER67"],
  ["NRCD", "CALIFORNIA-STOCKTON-NLETTER67"],
  ["NSSK", "CALIFORNIA-SISKIYOU-NLETTER67"],
  ["NVIC", "CALIFORNIA-VICTORVILLE-NLETTER67"],
  ["PJAT", "1056JS-E-NLETTER67"],
  ["PCLA", "1056LC-E-NLETTER67"],
  ["PMUR", "1056NJ-E-NLETTER67"],
  ["PCNJ", "CENTRALJERSEY-E-NLETTER67"],
  ["NTPC", "COLORADO-PUEBLO-NLETTER67"],
  ["NTBU", "CONNECTICUT-NORWICH-NLETTER67"],
  ["NDAC", "FLORIDA-LEESBURG-NLETTER67"],
  ["NDNJ", "FLORIDA-DAYTONA-NLETTER67"],
  ["NFTU", "FLORIDA-JACKSONVILLE-NLETTER67"],
  ["NLED", "FLORIDA-LAKELAND-NLETTER67"],
  ["NNWF", "FLORIDA-FORTWALTON-NLETTER67"],
  ["NOSB", "FLORIDA-OCALA-NLETTER67"],
  ["NPBD", "FLORIDA-PALMBEACHDAILY-NLETTER67"],
  ["NPPP", "FLORIDA-PALMBEACHPOST-NLETTER67"],
  ["NSAR", "FLORIDA-STAUGUSTINE-NLETTER67"],
  ["NSHT", "FLORIDA-SARASOTA-NLETTER67"],
  ["NTGS", "FLORIDA-GAINESVILLE-NLETTER67"],
  ["NTNH", "FLORIDA-PANAMACITY-NLETTER67"],
  ["NAUG", "GEORGIA-AUGUSTA-NLETTER67"],
  ["NSMN", "GEORGIA-SAVANNAH-NLETTER67"],
  ["NCDT", "MISSOURI-COLUMBIA-NLETTER67"],
  ["NPOH", "NEWHAMPSHIRE-PORTSMOUTH-NLETTER67"],
  ["NBCO", "NEWJERSEY-WILLINGBORO-NLETTER67"],
  ["NMP2", "NEWYORK-MPNNOW-NLETTER67"],
  ["NNJH", "NEWJERSEY-NEWTON-NLETTER67"],
  ["NCHE", "NEWYORK-PENNYAN-NLETTER67"],
  ["NDNS", "NEWYORK-DANSVILLE-NLETTER67"],
  ["NEVR", "NEWYORK-HORNELL-NLETTER67"],
  ["NEVT", "NEWYORK-HERKIMER-NLETTER67"],
  ["NLEA", "NEWYORK-CORNING-NLETTER67"],
  ["NOBD", "NEWYORK-UTICA-NLETTER67"],
  ["NSCA", "NEWYORK-BATH-NLETTER67"],
  ["NTHR", "NEWYORK-MIDDLETOWN-NLETTER67"],
  ["NCTR", "NORTHCAROLINA-ASHEBORO-NLETTER67"],
  ["NDIS", "NORTHCAROLINA-LEXINGTON-NLETTER67"],
  ["NGAG", "NORTHCAROLINA-GASTONIA-NLETTER67"],
  ["NHTN", "NORTHCAROLINA-TIMESNEWS-NLETTER67"],
  ["NJDN", "NORTHCAROLINA-JACKSONVILLE-NLETTER67"],
  ["NKFP", "NORTHCAROLINA-KINSTON-NLETTER67"],
  ["NSHS", "NORTHCAROLINA-SHELBY-NLETTER67"],
  ["NSTN", "NORTHCAROLINA-WILMINGTON-NLETTER67"],
  ["NSUJ", "NORTHCAROLINA-NEWBERN-NLETTER67"],
  ["NTFO", "NORTHCAROLINA-FAYETTEVILLE-NLETTER67"],
  ["NTIN", "NORTHCAROLINA-BURLINGTON-NLETTER67"],
  ["PNAS", "1056TN-E-NLETTER67"],
  ["PPEN", "1063NJ-E-NLETTER67"],
  ["PPOU", "1066PJ-E-NLETTER67"],
  ["PROC", "1070DC-E-NLETTER67"],
  ["PSAL", "1074SJ-E-NLETTER67"],
  ["PSTC", "1076SC-E-NLETTER67"],
  ["PSAS", "1078SC-E-NLETTER67"],
  ["PPAS", "1082DS-E-NLETTER67"],
  ["PSIF", "1085AL-E-NLETTER67"],
  ["PGRF", "1094GF-E-NLETTER67"],
  ["PJAM", "1098CL-E-NLETTER67"],
  ["PAPN", "WISCONSIN-APPLETON-NLETTER67"],
  ["PFDL", "WISCONSIN-FONDDULAC-NLETTER67"],
  ["PGRB", "WISCONSIN-GREENBAY-NLETTER67"],
  ["PMAC", "WISCONSIN-MANITOWOC-NLETTER67"],
  ["PMAD", "WISCONSIN-MARSHFIELD-NLETTER67"],
  ["POSH", "WISCONSIN-OSHKOSH-NLETTER67"],
  ["PSHE", "WISCONSIN-SHEBOYGAN-NLETTER67"],
  ["PSTP", "WISCONSIN-STEVENTSPT-NLETTER67"],
  ["PWAU", "WISCONSIN-WAUSAU-NLETTER67"],
  ["PWIR", "WISCONSIN-WISCRAPIDS-NLETTER67"],
  ["NALT", "ILLINOIS-ALEDO-NLETTER67"],
  ["NCDL", "ILLINOIS-CANTON-NLETTER67"],
  ["NCHI", "ILLINOIS-CHILLICOTHE-NLETTER67"],
  ["NCOU", "LOUISIANA-HOUMA-NLETTER67"],
  ["NDRA", "ILLINOIS-MONMOUTH-NLETTER67"],
  ["NGNS", "ILLINOIS-GENESEO-NLETTER67"],
  ["NGRM", "ILLINOIS-GALESBURG-NLETTER67"],
  ["NJOS", "ILLINOIS-PEORIA-NLETTER67"],
  ["NJST", "ILLINOIS-FREEPORT-NLETTER67"],
  ["NLNC", "ILLINOIS-LINCOLN-NLETTER67"],
  ["NMCV", "ILLINOIS-MACOMB-NLETTER67"],
  ["NMTN", "ILLINOIS-MORTON-NLETTER67"],
  ["NPDL", "ILLINOIS-PONTIAC-NLETTER67"],
  ["NPDT", "ILLINOIS-PEKIN-NLETTER67"],
  ["NPTC", "ILLINOIS-EASTPEORIA-NLETTER67"],
  ["NRRS", "ILLINOIS-ROCKFORD-NLETTER67"],
  ["NSJR", "ILLINOIS-SPRINGFIELD-NLETTER67"],
  ["NSTC", "ILLINOIS-KEWANEE-NLETTER67"],
  ["NWOO", "ILLINOIS-METAMORA-NLETTER67"],
  ["NWTR", "ILLINOIS-WASHINGTON-NLETTER67"],
  ["NDLJ", "NORTHDAKOTA-DEVILSLAKE-NLETTER67"],
  ["NABJ", "OHIO-AKRON-NLETTER67"],
  ["NALR", "OHIO-ALLIANCE-NLETTER67"],
  ["NATG", "OHIO-ASHLAND-NLETTER67"],
  ["NBEN", "OHIO-BARNESVILLE-NLETTER67"],
  ["NCOD", "OHIO-COLUMBUS-NLETTER67"],
  ["NDAJ", "OHIO-CAMBRIDGE-NLETTER67"],
  ["NDAR", "OHIO-WOOSTER-NLETTER67"],
  ["NIND", "OHIO-MASSILLON-NLETTER67"],
  ["NREC", "OHIO-KENTRAVENNA-NLETTER67"],
  ["NREP", "OHIO-CANTON-NLETTER70"],
  ["NTIR", "OHIO-DOVER-NLETTER67"],
  ["NBEE", "OKLAHOMA-BARTLESVILLE-NLETTER67"],
  ["NOKL", "OKLAHOMA-OKLAHOMACITY-NLETTER67"],
  ["NREG", "OREGON-EUGENE-NLETTER67"],
  ["NBCC", "PENNSYLVANIA-LEVITTOWN-NLETTER67"],
  ["NBCT", "PENNSYLVANIA-BEAVER-NLETTER67"],
  ["NEAG", "PENNSYLVANIA-HONESDALE-NLETTER67"],
  ["NECL", "PENNSYLVANIA-ELLWOOD-NLETTER67"],
  ["NECP", "PENNSYLVANIA-GREENCASTLE-NLETTER67"],
  ["NETN", "PENNSYLVANIA-ERIE-NLETTER67"],
  ["NINT", "PENNSYLVANIA-DOYLESTOWN-NLETTER67"],
  ["NPOR", "PENNSYLVANIA-STROUDSBURG-NLETTER67"],
  ["NTDA", "PENNSYLVANIA-SOMERSET-NLETTER67"],
  ["NWAR", "PENNSYLVANIA-WAYNESBORO-NLETTER67"],
  ["NNDN", "RHODEISLAND-NEWPORT-NLETTER67"],
  ["NHEJ", "SOUTHCAROLINA-SPARTANBURG-NLETTER67"],
  ["NTAN", "SOUTHDAKOTA-ABERDEEN-NLETTER67"],
  ["NWAT", "SOUTHDAKOTA-WATERTOWN-NLETTER67"],
  ["NDHE", "TENNESSEE-COLUMBIA-NLETTER67"],
  ["NOAK", "TENNESSEE-OAKRIDGE-NLETTER67"],
  ["NAGN", "TEXAS-AMARILLO-NLETTER67"],
  ["NAMT", "TEXAS-ANNAMELISSA-NLETTER67"],
  ["NGRR", "TEXAS-GLENROSE-NLETTER67"],
  ["NLAJ", "TEXAS-LUBBOCK-NLETTER67"],
  ["NMIM", "TEXAS-MIDLOTHIAN-NLETTER67"],
  ["NPRP", "TEXAS-PROSPER-NLETTER67"],
  ["NRCR", "TEXAS-BALLINGER-NLETTER67"],
  ["NVAL", "TEXAS-VANALSTYNE-NLETTER67"],
  ["NPRI", "VIRGINIA-PETERSBURG-NLETTER67"],
  ["PWES", "1084WT-E-NLETTER67"],
  ["PHAT", "1099HA-E-NLETTER67"],
  ["PVIN", "1106DJ-E-NLETTER67"],
  ["PLOU", "1107CJ-E-NLETTER67"],
  ["PGRE", "11120GN-E-NLETTER67"],
  ["PASH", "1122CT-E-NLETTER67"],
  ["PMOY", "1123MA-E-NLETTER67"],
  ["PSTA", "1125NL-E-NLETTER67"],
  ["PDEM", "1150DM-E-NLETTER67"],
  ["PAPP", "1171AP-E-NLETTER67"],
  ["PMOR", "1174DR-E-NLETTER67"],
  ["PARN", "1253AB-E-NLETTER67"],
  ["PAIM", "1254IM-E-NLETTER67"],
  ["PCCT", "1255CT-E-NLETTER67"],
  ["PEVC", "1256VC-E-NLETTER67"],
  ["PHGL", "1257GL-E-NLETTER67"],
  ["PMJS", "1258MJ-E-NLETTER67"],
  ["PBKS", "1261KS-E-NLETTER67"],
  ["PKNS", "1262NS-E-NLETTER67"],
  ["PMCA", "1263CA-E-NLETTER67"],
  ["PNDN", "1264NN-E-NLETTER67"],
  ["PRRS", "1265RS-E-NLETTER67"],
  ["PSAT", "1266AT-E-NLETTER67"],
  ["PTCN", "1267CN-E-NLETTER67"],
  ["PVCS", "1270CS-E-NLETTER67"],
  ["PNM5", "1278CA-E-NLETTER67"],
  ["PNM6", "1278DN-E-NLETTER67"],
  ["PNM7", "1278DT-E-NLETTER67"],
  ["PNM2", "11278LC-E-NLETTER67"],
  ["NBAR", "MASSACHUSETTS-BARNSTABLE-NLETTER67"],
  ["NCCT", "MASSACHUSETTS-HYANNIS-NLETTER67"],
  ["NENT", "MASSACHUSETTS-BROCKTON-NLETTER67"],
  ["NGAR", "MASSACHUSETTS-GARDNER-NLETTER67"],
  ["NHER", "MASSACHUSETTS-FALLRIVER-NLETTER67"],
  ["NLAN", "MASSACHUSETTS-HOLDEN-NLETTER67"],
  ["NLEO", "MASSACHUSETTS-LEOMINSTER-NLETTER67"],
  ["NMSC", "MASSACHUSETTS-MILLBURY-NLETTER67"],
  ["NMWD", "MASSACHUSETTS-FRAMINGHAM-NLETTER67"],
  ["NPAL", "MASSACHUSETTS-QUINCY-NLETTER67"],
  ["NSTT", "MASSACHUSETTS-NEWBEDFORD-NLETTER67"],
  ["NTDG", "MASSACHUSETTS-TAUNTON-NLETTER67"],
  ["NTEG", "MASSACHUSETTS-WORCESTER-NLETTER67"],
  ["NWKL", "MASSACHUSETTS-WICKEDLOCAL-NLETTER70"],
  ["PWTR", "1271TR-E-NLETTER67"],
  ["PNM8", "1278RN-E-NLETTER67"],
  ["PNM3", "1278M3-E-NLETTER67"],
  ["PTX1", "1278TM-E-NLETTER67"],
  ["PPLD", "1287DN-E-NLETTER67"],
  ["PPES", "1287ES-E-NLETTER67"],
  ["PPPO", "1287PO-E-NLETTER67"],
  ["PPYR", "1287YR-E-NLETTER67"],
  ["PNJM", "1300TR-E-NLETTER67"],
  ["PSAY", "1528DT-E-NLETTER67"],
  ["PSTG", "1530SP-E-NLETTER67"],
  ["PPHX", "1531AR-E-NLETTER67"],
  ["PLAI", "1532JC-E-NLETTER67"],
  ["PRIC", "1532PI-E-NLETTER67"],
  ["PMUN", "1532SP-E-NLETTER67"],
  ["PTAL", "1558TD-E-NLETTER67"],
  ["PVIT", "VISALIATULARE-E-NLETTER67"],
  ["NHEN", "INDIANA-BLOOMINGTON-NLETTER67"],
  ["NRPT", "INDIANA-MARTINSVILLE-NLETTER67"],
  ["NSBT", "INDIANA-SOUTHBEND-NLETTER67"],
  ["NSEW", "INDIANA-SPENCER-NLETTER67"],
  ["NTMN", "INDIANA-BEDFORD-NLETTER67"],
  ["NATR", "IOWA-AMES-NLETTER67"],
  ["NBNR", "IOWA-BOONE-NLETTER67"],
  ["NDCN", "IOWA-DALLASCOUNTY-NLETTER67"],
  ["NEYE", "IOWA-BURLINGTON-NLETTER67"],
  ["NNEV", "IOWA-NEVADA-NLETTER67"],
  ["NPRC", "IOWA-PERRY-NLETTER67"],
  ["NSCH", "IOWA-STORYCITY-NLETTER67"],
  ["NTCT", "IOWA-TRICOUNTY-NLETTER67"],
  ["NHUT", "KANSAS-HUTCHINSON-NLETTER67"],
  ["NSAJ", "KANSAS-SALINA-NLETTER67"],
  ["NTCJ", "KANSAS-TOPEKA-NLETTER67"],
  ["NTHM", "MARYLAND-HAGERSTOWN-NLETTER67"]
]);

export default NEWSLETTER_CONFIG;
