import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

interface IProps {
  color: string;
}

const SMSIcon: React.FC<IProps> = ({ color }) => {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path
        fill={color}
        fillRule="evenodd"
        d="M9.943 18.4L4.8 22v-3.6h-.008C3.802 18.4 3 17.585 3 16.605V5.795C3 4.803 3.797 4 4.792 4h14.416C20.198 4 21 4.815 21 5.795v10.81c0 .992-.797 1.795-1.792 1.795H9.943zm-1.808-4.401c.337 0 .644-.04.832-.09v-1.098c-.09.03-.277.06-.465.06-.327 0-.525-.15-.525-.614v-2.178h1.02v-1.07h-1.02V7.683H6.541v1.326h-.663v1.07h.663v2.346c0 1.04.446 1.574 1.594 1.574zm1.208-.099h1.594l1.09-1.515 1.088 1.515h1.643l-1.9-2.495L14.65 9.01h-1.594l-.98 1.386-.98-1.386H9.451l1.792 2.396-1.9 2.495zm7.91.099c.337 0 .644-.04.832-.09v-1.098c-.09.03-.277.06-.465.06-.327 0-.525-.15-.525-.614v-2.178h1.02v-1.07h-1.02V7.683h-1.436v1.326h-.663v1.07h.663v2.346c0 1.04.446 1.574 1.594 1.574z"
      />
    </SvgIcon>
  );
};

export default SMSIcon;
