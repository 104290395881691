import { useConfig } from "@/hooks/useAppState";
import { WebLayout } from "@gannettdigital/community-hub-components";
import React, { useMemo } from "react";

interface IProps {
  children: React.ReactNode;
  hideHeaderAndFooter?: boolean;
}

const Main: React.FC<IProps> = ({ children, hideHeaderAndFooter }) => {
  const config = useConfig();

  const { header, footer } = useMemo(() => {
    if (hideHeaderAndFooter) {
      return { header: "", footer: "" };
    }
    return config;
  }, [hideHeaderAndFooter, config]);

  return (
    <WebLayout header={header} footer={footer}>
      <>{children}</>
    </WebLayout>
  );
};

export default Main;
