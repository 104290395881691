import styled from "@/components/contexts/ThemeProvider/styled";
import { CircularProgress } from "@mui/material";

const LoadingAnimation = styled(CircularProgress)(({ theme }) => ({
  position: "absolute",
  width: "10%",
  left: "45%",
  top: "45%",
  color: theme.palette.primary.main,
  zIndex: 1
}));

export default LoadingAnimation;
