import { FiltersContext } from "@/components/contexts";
import { useAnalytics } from "@/services/analyticsService";
import { Pagination } from "@gannettdigital/community-hub-components";
import { Box, Grid } from "@mui/material";
import React, { useContext } from "react";

interface IProps {
  count: number;
  page: number;
}

const Paginate: React.FC<IProps> = ({ count, page }) => {
  const { goToPage } = useContext(FiltersContext);
  const { trackEvent } = useAnalytics();

  return (
    <Grid container item justifyContent="center">
      <Box mb={{ xs: 2, md: 4 }}>
        <Pagination
          count={count}
          page={page}
          onChange={(e, newPage) => {
            if ((e.currentTarget as HTMLElement).innerText) {
              trackEvent({
                category: "pagination",
                action: "click",
                label: `Number ${newPage}`,
              });
            } else {
              trackEvent({
                category: "pagination",
                action: "click",
                label: `Arrow ${newPage}`,
              });
            }
            return goToPage(newPage);
          }}
        />
      </Box>
    </Grid>
  );
};

export default Paginate;
