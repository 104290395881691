import {
  addDays,
  addHours,
  endOfDay,
  endOfYesterday,
  format,
  isValid,
  startOfDay,
  startOfToday,
  startOfYesterday,
  subDays,
  subHours,
  subWeeks
} from "date-fns";

export const DateRange = {
  Today: "Today",
  Yesterday: "Yesterday",
  Last7Days: "Last7Days",
  Custom: "Custom"
};

export const DateRangeOptions = [
  { value: DateRange.Today, label: "Today" },
  { value: DateRange.Yesterday, label: "Yesterday" },
  { value: DateRange.Last7Days, label: "Last 7 Days" },
  { value: DateRange.Custom, label: "Custom" }
];

export function getEndOfDay(date: Date) {
  return endOfDay(date);
}

export function getStartOfDay(date: Date) {
  return startOfDay(date);
}

export function getBasicDate(date: string | Date) {
  const d = new Date(date);
  return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
}

// Ignore timezone offset for end of day
export const getEndDate = (date: string | Date) => {
  const dateObject = new Date(date);
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1;
  const day = dateObject.getDate();

  return `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""
    }${day}T23:59:59.999Z`;
};

// Ignore timezone offset and zero out day
export const getZeroDate = (date: string | Date) => {
  const dateObject = new Date(date);
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1;
  const day = dateObject.getDate();

  return `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""
    }${day}T00:00:00.000Z`;
};

export function getDatesInRange(range: string) {
  const today = startOfToday();
  const endOfToday = endOfDay(new Date());

  switch (range) {
    case DateRange.Yesterday: {
      const yesterdayStart = startOfYesterday();
      const yesterdayEnd = endOfYesterday();

      return {
        after: yesterdayStart,
        before: yesterdayEnd
      };
    }

    case DateRange.Last7Days:
      return {
        after: subWeeks(today, 1),
        before: endOfToday
      };

    default:
      return {
        after: today,
        before: endOfToday
      };
  }
}

export function datesAreOnSameDay(a: Date, b: Date) {
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  );
}

export function formatDate(dateString: string | Date, formatString: string) {
  try {
    return format(new Date(dateString), formatString);
  } catch (e) {
    return "unknown";
  }
}

export function dateHeader(date: string | Date) {
  try {
    const dateString = startOfDay(new Date(date));
    if (isValid(dateString)) {
      return formatDate(dateString, "EEEE, LLLL dd, yyyy");
    }
  } catch (e) {
    return "unknown";
  }
}

// durationSingleLine is used for non html needs such as an email body
export function durationSingleLine(
  startDateString: string | Date,
  endDateString: string | Date | undefined
) {
  const dateFormat = "MMM. dd, yyyy";
  const timeFormat = "h:mm aaaa";
  const dateTimeFormat = `${dateFormat} ● ${timeFormat}`;
  if (!endDateString || endDateString === "") {
    const date = formatDate(startDateString, dateFormat);
    const hoursMinutes = formatDate(startDateString, timeFormat);
    return `${date} ● ${hoursMinutes}`;
  }
  if (datesAreOnSameDay(new Date(startDateString), new Date(endDateString))) {
    const date = formatDate(startDateString, dateFormat);
    const timeStart = formatDate(startDateString, timeFormat);
    const timeEnd = formatDate(endDateString, timeFormat);
    return `${date} ● ${timeStart} - ${timeEnd}`;
  }
  const dateTimeStart = formatDate(startDateString, dateTimeFormat);
  const dateTimeEnd = formatDate(endDateString, dateTimeFormat);
  return `From ${dateTimeStart} to ${dateTimeEnd}`;
}

function getPreviousDate(offset: number) {
  return new Date(
    new Date(new Date().setDate(new Date().getDate() - offset)).setHours(
      0,
      0,
      0,
      0
    )
  );
}

export function getUpperBound(fromString: string) {
  let upperBound;
  switch (fromString) {
    case "Most recent":
      upperBound = null;
      break;
    case "Yesterday":
      upperBound = getPreviousDate(1);
      break;
    case "Past week":
      upperBound = getPreviousDate(7);
      break;
    case "Past month":
      upperBound = getPreviousDate(30);
      break;
    default:
      throw new Error(`From duration ${fromString} not recognized`);
  }
  return upperBound;
}

export function formattedDate(date: string) {
  return formatDate(date, "MMMM dd, yyyy");
}

export function formattedDateString(dateString: string) {
  return formatDate(dateString.replace(/-/g, "/"), "MMMM dd, yyyy");
}

export function formattedStartOfDay(date: string | Date) {
  const start = startOfDay(new Date(date));
  return formatDate(start, "MMMM dd, yyyy");
}

export function displayDate(incommingDate: string | Date): string {
  let formatted = "";
  const date = new Date(incommingDate);
  if (isValid(date)) {
    const month = formatDate(date, "MMMM");
    const day = date.toString().slice(8, 10); // use string for day
    const year = formatDate(date, "yyyy");
    formatted = `${month} ${day}, ${year}`;
  }
  return formatted;
}

export function getTheDateAfter(dateString: string | Date) {
  return addDays(new Date(dateString), 1);
}

export function getTheDateBefore(dateString: string | Date) {
  return subDays(new Date(dateString), 1);
}

export function isToday(dateString: string | Date) {
  return getZeroDate(dateString) === getZeroDate(new Date());
}

export function formatToDBDate(date: string | Date) {
  return format(new Date(date), "yyyy-MM-dd");
}

export function formatFromDBDate(date: string | Date) {
  date = new Date(date);
  const offset = date.getTimezoneOffset() / 60;
  if (offset < 0) return subHours(date, Math.abs(offset));
  else return addHours(date, offset);
}
