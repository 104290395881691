import styled from "@/components/contexts/ThemeProvider/styled";
import {
    Typography,
    TypographyProps
} from "@gannettdigital/community-hub-components";

const CustomFormSectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  marginBottom: 14,
  [theme.breakpoints.up("sm")]: {
    fontSize: 18,
    marginBottom: 18
  }
}));

const FormSectionTitle: React.FC<TypographyProps> = props => (
  <CustomFormSectionTitle variant="h2" {...props} />
);

export default FormSectionTitle;
