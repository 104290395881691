import {
  theme as baseTheme,
  unifySansBoldFontFamily
} from "@gannettdigital/community-hub-components";
import { createTheme } from "@mui/material/styles";
import merge from "lodash/merge";

// extracted from zeplin styleguide
export const Colors = {
  ...baseTheme.palette.all
};

declare module "@mui/material/styles" {
  interface Palette {
    // Add `all` as a palette field
    custom: typeof Colors;
  }

  interface PaletteOptions {
    // Allow configuration using `createTheme`
    custom?: typeof Colors;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960, // default = 900
      lg: 1140, // default = 1200
      xl: 1536
    }
  },
  palette: {
    ...baseTheme.palette,
    custom: {
      ...Colors
    }
  },
  typography: {
    ...baseTheme.typography,
    color: Colors.gray2,
    h1: {
      fontFamily: unifySansBoldFontFamily,
      fontSize: 28
    }
  },
  components: {
    ...baseTheme.components,
    MuiButton: merge(baseTheme.components?.MuiButton, {
      styleOverrides: {
        root: {
          borderRadius: 0
        }
      }
    })
  }
});

export default theme;
