import styled from "@/components/contexts/ThemeProvider/styled";
import { Colors } from "@/components/contexts/ThemeProvider/theme";
import { unifySansRgFontFamily } from "@gannettdigital/community-hub-components";
import MuiFormControl from "@mui/material/FormControl";
import MuiMenuItem from "@mui/material/MenuItem";
import MuiSelect from "@mui/material/Select";

export const FormControl = styled(MuiFormControl)({
  height: 42
});

export const Select = styled(MuiSelect)(({ theme }) => ({
  fontFamily: unifySansRgFontFamily,
  maxHeight: 42,
  fontSize: 14,
  color: "rgba(0, 0, 0, 0.5)",

  "& > svg": {
    color: "black"
  },

  "& .MuiSelect-select": {
    paddingLeft: "10.4px"
  },
  "&:focused:active": {
    borderColor: theme.palette.primary.main
  }
}));

export const MenuItem = styled(MuiMenuItem)(() => ({
  fontFamily: unifySansRgFontFamily + "!important",
  fontWeight: 100,
  fontSize: 16,
  display: "block !important",
  padding: "6px 16px !important",

  "&:hover": {
    backgroundColor: `${Colors.blue15}80 !important`
  },

  "& .Mui-focusVisible": {
    backgroundColor: `${Colors.blue15} !important`
  }
}));
