import styled from "@/components/contexts/ThemeProvider/styled";
import {
    Typography as CustomTypography,
    unifySansBoldFontFamily
} from "@gannettdigital/community-hub-components";

export const Typography = styled(CustomTypography)(({ theme }) => ({
  "&.MuiTypography-body2": {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    fontSize: 14,
    marginTop: "-5px",
    fontFamily: unifySansBoldFontFamily,
    whiteSpace: "nowrap"
  }
}));

export const Container = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2),

  [theme.breakpoints.up("sm")]: {
    marginBottom: theme.spacing(0)
  }
}));
