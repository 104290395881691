export const getStorage = (name: string) => {
  return sessionStorage.getItem(name) || "";
};

export const setStorage = (name: string, value: any) => {
  const str = JSON.stringify(value);
  return sessionStorage.setItem(name, str);
};

export const parseStorage = (name: string) => {
  const storage = getStorage(name);
  try {
    return storage ? JSON.parse(storage) : "";
  } catch (_) {
    return storage;
  }
};

export const removeStorageDuplicates = async (
  name: string,
  existing: string,
  updated: any
) => {
  const current = parseStorage(name);
  if (!current) return existing;

  // remove duplicates by matching index & slug
  const combined = [
    ...Array.from(
      new Map(
        [...current, ...updated].map(v => [
          JSON.stringify([v.index, v.slug]),
          v
        ])
      ).values()
    )
  ];

  return setStorage(name, combined);
};
