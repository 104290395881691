import {
  SubmitPageFeatureImage as FeatureImage,
  SubmitPageSwooshImage as SwooshImage,
  SubmitPageTitle as Title
} from "@/components/atoms";
import { useAnalytics } from "@/services/analyticsService";
import { Typography } from "@gannettdigital/community-hub-components";
import React from "react";
import {
  Container,
  FeatureFlexContainer,
  ItemContainer,
  TextContainer,
} from "./SubmitPageFeaturesSection.styled";

export enum FeatureType {
  lifeStory = "life-story",
  memoryBoard = "memory-board",
  lifeInPictures = "life-in-pictures",
  services = "services",
  flowers = "flowers",
  donations = "donations",
}

interface IFeatureItemProps {
  title: string;
  content: string | React.ReactNode;
  featureType: FeatureType;
  flexDirection?: "row" | "row-reverse";
  showSwoosh?: boolean;
}

const FeatureItem: React.FC<IFeatureItemProps> = ({
  title,
  content,
  featureType,
  flexDirection = "row",
  showSwoosh = true,
}) => {
  return (
    <ItemContainer>
      <FeatureFlexContainer flexDirection={flexDirection}>
        <TextContainer>
          <Title variant="body2">{title}</Title>
          <Typography
            fontSize={{ xs: 16, md: 18 }}
            variant="body2"
            mb={{ xs: 0.5, sm: 2, md: 4 }}
          >
            {content}
          </Typography>
        </TextContainer>
        <FeatureImage featureType={featureType} />
      </FeatureFlexContainer>
      {showSwoosh && (
        <>
          <SwooshImage className="desktop" flip={flexDirection === "row"} />
          <SwooshImage className="mobile" />
        </>
      )}
    </ItemContainer>
  );
};

const FeaturesSection: React.FC = () => {
  const { trackEvent } = useAnalytics();

  return (
    <Container>
      <FeatureItem
        title="Life story"
        content="When a life reaches beyond a local community, so should their
            legacy. We're here to help you honor your loved one and to tell
            their story."
        featureType={FeatureType.lifeStory}
      />
      <FeatureItem
        title="Memory board"
        content="A gathering place to connect with family and friends, to share memories and condolences, and to pay tribute to those we love."
        featureType={FeatureType.memoryBoard}
        flexDirection="row-reverse"
      />
      <FeatureItem
        title="Life in pictures"
        content={
          <>
            <b>Coming Soon!</b> Tell the bigger picture by adding a gallery of
            photos from your loved one&apos;s life.
          </>
        }
        featureType={FeatureType.lifeInPictures}
      />
      <FeatureItem
        title="Services"
        content={
          <>
            <b>Coming Soon!</b> Making it easy to know when and where memorial
            events are planned with interactive maps and directions to get
            there.
          </>
        }
        featureType={FeatureType.services}
        flexDirection="row-reverse"
      />
      <FeatureItem
        title="Flowers"
        content="Sourced from a network of local florists, readers can send heartfelt bereavement flowers and arrangements to pay their respects."
        featureType={FeatureType.flowers}
      />
    </Container>
  );
};

export default FeaturesSection;
