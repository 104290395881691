import { IGetWebConfigQuery } from "@/graphql/queries/__generated__/getWebConfig.types";
import NEWSLETTER_CONFIG from "@/services/newsletter";
import get from "lodash/get";
import getConfig from "next/config";
import { useStore } from "react-redux";

const useApp = () => {
  const store = useStore();
  return store.getState().app;
};

export const useConfig = () => {
  const app = useApp();
  return get(app, "config");
  // IGetWebConfigQuery["web_config"]
};

export const useNative = () => {
  const app = useApp();
  return get(app, "isNativeApp");
};

export const useExternalLinks = () => {
  return get(useConfig(), "external_links", []);
};

export const usePublication = () => {
  return get(useConfig(), "publication");
};

export const useTitle = () => {
  return get(useConfig(), "title");
};

export const useDomain = () => {
  return get<string | null>(useConfig(), "domain", null);
};

export const useWebFilters = () => {
  return get<IGetWebConfigQuery["web_config"][0]["web_config_filters"]>(useConfig(), "web_config_filters", []);
};

export const useWebConfigFilterTags = () => {
  return get(useWebFilters(), "[0].web_config_filter_tags", []);
};

export const usePubInfo = () => {
  return get(usePublication(), "publication_info[0]", []);
};

export const useSiteCode = () => {
  return get<string | null>(usePublication(), "site_code", null);
};

export const usePublicationId = () => {
  return get(usePublication(), "id");
};

export const useNewsletterConfig = () => {
  const siteCode = useSiteCode();
  const domain = useDomain();

  if (!siteCode || !domain) return null;

  const listCode = NEWSLETTER_CONFIG.get(siteCode);

  if (!listCode) return null;

  return {
    domain,
    siteCode,
    listCode,
    endpointUrl: `https://user.${domain}/${siteCode}-GUP/user/newsletters/`
  };
};

export const useWhitelist = (whitelistName: string) => {
  const siteCode = useSiteCode();
  if (!siteCode) return false;

  const { publicRuntimeConfig } = getConfig();

  const whitelistCsv = publicRuntimeConfig[`${whitelistName}`];

  return whitelistCsv && isMarketWhitelisted(siteCode, whitelistCsv);
};

export const isMarketWhitelisted = (siteCode: string, whitelistCsv: string) => {
  return whitelistCsv.indexOf(siteCode) > -1;
};
