import initializeStore from "@/redux/initializeStore";
import React from "react";

const isServer = typeof window === "undefined";

function getOrCreateStore(initialState: any) {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) {
    return initializeStore(initialState);
  }

  // Create store if unavailable on the client and set it on the window object
  if (window && !window.__NEXT_REDUX_STORE__) {
    window.__NEXT_REDUX_STORE__ = initializeStore(initialState);
  }
  return window.__NEXT_REDUX_STORE__;
}

const WithAReduxStore = (App: any) => {
  class AppWithRedux extends React.Component {
    reduxStore: any;
    static async getInitialProps(appContext: any) {
      const newAppContext = appContext;
      // Get or Create the store with `undefined` as initialState
      // This allows you to set a custom default initialState
      const reduxStore = getOrCreateStore({ app: {} });

      // Provide the store to getInitialProps of pages
      newAppContext.ctx.reduxStore = reduxStore;

      let appProps = {};
      if (typeof App.getInitialProps === "function") {
        appProps = await App.getInitialProps(newAppContext);
      }

      return {
        ...appProps,
        initialReduxState: reduxStore.getState()
      };
    }

    constructor(props: any) {
      super(props);
      this.reduxStore = getOrCreateStore(props.initialReduxState);
    }

    render() {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <App {...this.props} reduxStore={this.reduxStore} />;
    }
  }

  return AppWithRedux;
};

export default WithAReduxStore;
