import styled from "@/components/contexts/ThemeProvider/styled";
import {
    Link as CustomLink,
    LinkProps,
    unifySansRgFontFamily
} from "@gannettdigital/community-hub-components";

const Link = styled(
  (
    props: LinkProps & {
      fontSize?: {
        md?: string | number;
        lg?: string | number;
      };
    }
  ) => <CustomLink {...props} />,
  {
    shouldForwardProp: prop => prop !== "fontSize"
  }
)(({ theme, fontSize }) => ({
  fontFamily: unifySansRgFontFamily,
  textAlign: "center",
  fontSize: 16,
  fontWeight: 400,

  [theme.breakpoints.up("md")]: {
    fontSize: fontSize?.md || 16
  },

  [theme.breakpoints.up("lg")]: {
    fontSize: fontSize?.lg || 16
  }
}));

export default Link;
